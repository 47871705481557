import React, { createContext, useState, ReactNode } from 'react';

interface AccessibilityLanguageContextProps {
  showLanguage: boolean;
  setShowLanguage: React.Dispatch<React.SetStateAction<boolean>>;
  showAccessibility: boolean;
  setShowAccessibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccessibilityLanguageContext = createContext<AccessibilityLanguageContextProps | undefined>(undefined);

export const AccessibilityLanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showLanguage, setShowLanguage] = useState(true);
  const [showAccessibility, setShowAccessibility] = useState(true);

  return (
    <AccessibilityLanguageContext.Provider value={{ showLanguage, setShowLanguage, showAccessibility, setShowAccessibility }}>
      {children}
    </AccessibilityLanguageContext.Provider>
  );
};
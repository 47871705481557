import { RouteComponentProps } from 'react-router'
import useForm from 'react-hook-form'
import { IWitnessProfileProps, witness_type } from './witnesses'
import { IHostSalonForTable, ISalon as IHostSalon } from './hosts'
import { IGuestProfile } from './guests'

export interface City {
  id: number
  name: string
  country: string
}

export interface Languages {
  id: number
  name: string
}

export interface Populations {
  id: number
  name: string
}

interface ISalonDays {
  date: string
  day_type: string
}

export interface Community {
  id: number
  name: string
}

export interface Witness {
  hosted_before: boolean
  archived: boolean
  availability_status: string
  available_for_teaming: boolean
  city: string
  community: string
  first_name: string
  fullName: string
  id: number
  languages: number[]
  last_name: string
  special_population_text: string
  witness_type: witness_type
}
export interface IAdminSalonsDashboard {
  didnt_contacted: number
  digital_salon: number
  not_digital_salon: number
  total_salons: number
  waiting_for_witness: number
}

export interface IAdminHostsDashboard {
  total_hosts: string
  total_is_org: string
  total_didnt_create_salon_yet: string
  total_attend_preperation_evening: string
  total_was_hosts_before: string
}

export interface IAdminGuestsDashboard {
  total_guests: string
  total_didnt_finish_registration_this_year: string
  total_without_salon_this_year: string
  total_with_salon_this_year: string
  total_with_salon_other_year: string
  total_with_salon: string
}

export interface IAdminWitnessesDashboard {
  total_witnesses: string
  total_holocaust_survivors: string
  total_generation_2: string
  total_generation_3: string
  total_experts: string
  total_waiting_for_conservation: string
  total_waiting_for_assignment: string
  total_waiting_for_contact: string
}

export interface Language {
  id: number
  name: string
}

export interface AdditionalProgram {
  name: string
}

interface filterTableField {
  title: string
  fieldKey: string
  canSortBy: boolean
  sortKey: string
  width: number
  isToolTip?: boolean
  special_attention_text?: string
}

export interface IFilterTableProps {
  tableName?: string
  columnsList: Array<filterTableField>
  data: Array<{ id: number; [key: string]: number | string | boolean }>
  onSortTable: (sortByString: string, isSortReverse: boolean) => void
  totalLinesAmount?: number
  onPageChange?: (pageNumber: number) => void
  linesCountNoFilters?: number
  filtersCount?: number
  cleanFilters?: () => void
  userAllowActionBar: boolean
  lineClickHandleFieldName?: string
  navigationLink?: string
  isNewTabOnNavigation?: boolean
  isTableTab?: boolean
  tableTabSelected?: string
  setTableTabSelected?: React.Dispatch<React.SetStateAction<string>>
  isTabelHeaderOutside?: boolean
  isLinkedLine?: boolean
  onLineClick?: (tableRow: any) => void
  isLoading?: boolean
  exportTableDataName?: string
  getParamsForExport?: any
}

export interface ITableHeaderCellProps {
  direction: 'ltr' | 'rtl'
  isClickable: boolean
}

export interface ISortArrowsProps {
  isSortBy: boolean
  isSortReverse: boolean
}

export interface sideBarStyleProps {
  isOpen: boolean
  pageDirection: 'rtl' | 'ltr'
}

export interface ITableHeaderProps {
  tableName: string
  totalLinesAmount: number
  onPageChange: (pageNumber: number) => void
  linesCountNoFilters: number
  filtersCount: number
  cleanFilters: () => void
  isTeamingTableTab?: boolean
  tableTabSelected?: string
  setTableTabSelected?: React.Dispatch<React.SetStateAction<string>>
  sortTableBy?: { sortByString: string; isSortReverse: boolean }
}

export interface IActionsBarProps {
  selectedCount: number
  handelCleanSelectedLines: () => void
  onDelete: () => void
  dataForExportCSV: any
  exportTableDataName?: string
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ITableObj {
  title: string
  fieldKey: string
  canSortBy: boolean
}

export interface TableHeadLineProps {
  columnsList: Array<filterTableField>
  isSelectAllLines: boolean
  onToggleSelectAllLines: () => void
  onSortTable: (sortByString: string, isSortReverse: boolean) => void
  userAllowActionBar: boolean
}

export interface IProfileCardHeadersProps {
  title: string
  isEditable: boolean
  isFocused?: boolean
  isEditMode?: boolean
  modalOptions?: any
  onToggleEditMode?: (isEdit: boolean) => void
  form?: string
  openEditProfileCard?: () => void
  isEditModal?: boolean
  isModal?: boolean
  shouldOpenCancelModal?: boolean
  borderRadius?: string
  guest?: IGuestProfile
}
interface idMetchProps {
  id: string
}

export interface IWitnessProfile extends RouteComponentProps<idMetchProps> {
  getUserProfile: (id: string) => void
}

export interface IWitnessPersonalDetails {
  witness?: IWitnessProfileProps
  salons?: { [key: string]: any }
  witnessId?: string
  witnessYearComment?: Array<{ [key: string]: any }>
  witnessComment?: any
}

export interface IWitnessPersonalDetailsInfoProps {
  witness: IWitnessProfileProps
}

export interface IwitnessSalons {
  assignment_time: null
  can_afternoon: boolean
  can_evening: boolean
  can_morning: boolean
  contacted_by_host: boolean
  host_name: string
  id: number
  salon_date: string
  witness: number | null
  witness_name: string
}

export interface ISalon {
  id: number
  assigned_by: string
  assignment_time: string
  host_contacted_witness: boolean
  host_name: string
  host_salon: number
  salon_time: string
  witness_name: string
  host: number
}

export interface AvailableThisYearProps {
  assignsSalons?: Array<ISalon>
  isOpenToAssigned?: boolean
  isEditMode?: boolean
  salondays: ISalonDays[]
}

export interface AvailabilityStatusTypes {
  availabilityStatus:
    | 'not_contact_this_year'
    | 'did_not_answer'
    | 'available_this_year'
    | 'not_available_this_year'
}

export interface ISalonsAssignsProps {
  salons: Array<ISalon>
  isEditMode: boolean
  salondays: ISalonDays[]
}

export interface IAvailabilityAssignProps {
  salons: Array<IwitnessSalons>
}

export interface IStatusNoteProps {
  noteType: string
  note: string
  register: ReturnType<typeof useForm>['register']
}

export interface witnessAssignFormProps {
  archived: boolean
  availability_status: string
  archived_free_text: string
  archived_reason: string
  unsuccessful_contact_date: string
  not_interested_this_year_free_text: string
  call_again_free_text: string
  want_to_participate_amount_salons: number
}

export interface ILastCallTimeInputProps {
  date: string
  onDateChange: (date: string) => void
}

export interface AvailabilityAssignEditProps {
  toggleIsEditMode: () => void
  witnessId: string
  onDelete: (salon: Object) => void
  handleFormSubmit: (data: any, formData: any) => void
  salonsAvailabilityList: Array<IwitnessSalons>
}

export interface IWitnessStatusInfoProps {
  archived: boolean
  availability_status: string
  unsuccessful_contact_date: string
  not_interested_this_year_free_text: string
  call_again_free_text: string
  archived_free_text: string
  archived_reason: string
  witnessFutureSalons: Array<ISalon>
  wantTestifyMoreThenOnce: number
  salondays: ISalonDays[]
  availableForTeaming: boolean
  salons: Array<IwitnessSalons>
}

export interface IWitnessStatusEditProps {
  witnessFutureSalons: Array<ISalon>
  toggleisEditMode: () => void
  salondays: ISalonDays[]
  witness: IWitnessProfileProps
  handleFormSubmit: (data: IWitnessStatusInfoProps) => void
  salons: Array<IwitnessSalons>
}

export interface ICardStatusHeaderEditProps {
  archived: boolean
  availability_status: string
  archived_reason: string
  unsuccessful_contact_date: string
  setValue: ReturnType<typeof useForm>['setValue']
}

export interface IProfileHeaderProps {
  hostName: string
  hostSalonLength: number
  isOrg: boolean
  onClickBack: () => void
  openNewSalonModal?: () => void
}

export interface IAdminHostProfileProps
  extends RouteComponentProps<idMetchProps> {}

export interface IAdminGuestProfileProps
  extends RouteComponentProps<idMetchProps> {}

export interface IModalOptions {
  cbConfirm?: () => void
  options?: {
    title?: string
    subtitle?: string
    ok?: string
    cancel?: string
    style?: {}
  }
  cbCancel?: () => void
}

export interface IWitnessAvailibalty {
  salons: Array<IwitnessSalons>
  witnessId: string
}

export enum teaming_status_options {
  not_contact_this_year = 'not_contact_this_year',
  assigned = 'assigned',
  waiting_for_first_assignment = 'waiting_for_first_assignment',
  waiting_for_more_assignments = 'waiting_for_more_assignments',
}

export enum ESideBarErrors {
  not_contact_this_year = 'not_contact_this_year',
  salons_not_found = 'salons_not_found',
  witnesses_not_found = 'witnesses_not_found',
  no_results_found = 'no_results_found',
  none = '',
}
export interface IWitnessForTeaming {
  id: number
  full_name: string
  city_name: string
  address: string
  teaming_status: teaming_status_options
  lat: number
  lng: number
  special_population: true
}

export interface IHostSalonForTeaming {
  id: number
  host: number
  contact_name: string
  lat: number
  lng: number
  address: string
  city_name: string
  teaming_status: teaming_status_options
}

export interface IWitnessSalonAvialabilityForTeaming {
  id: number
  salon_date: string
  can_morning: boolean
  can_afternoon: boolean
  can_evening: boolean
}
export interface IDetailedWitnessForTeaming {
  id: number
  full_name: string
  contact_phone: string
  city_name: string
  address: string
  stairs: boolean
  accessibility_need: string
  languages: Array<string>
  hosted_before: boolean
  special_attention_text: string
  witness_type: witness_type
  special_population: string[]
  witness_salons: Array<IWitnessSalonAvialabilityForTeaming>
  lat: string | number
  lng: string | number
  contact_name?: string
  translated_string?: string
  notPreciseLocation?: boolean
  can_use_zoom: boolean
  distance: number;
}

export interface IDetailedHostSalonForTeaming {
  id: number
  host: number
  contact_name: string
  event_time: Date
  contact_phone: string
  org_name: string
  address: string
  city_name: string
  is_assigned: boolean
  floor: number
  elevator: boolean
  language: string
  hosted_before: boolean
  special_population: boolean
  special_attention_text: string
  lat: string | number
  lng: string | number
  contacted: boolean
  salon_id: number
  witness_salons?: any
  translated_string?: string
  notPreciseLocation?: boolean
  is_digital: boolean
  accessibility?: string[]
  distance: number;
}

export interface TableTeamingParams {
  count: number
  next: string
  previous: string | null
  total_no_filters: number
}

export interface ISalonState {
  salonsList: Array<IHostSalonForTable>
  salonsFullList: Array<IHostSalonForTable>
  salonsCountAllPages: number
  isGetSalonsListLoading: boolean
  totalSalonsAmountNoFilters: number
  nextFilters: string
  previousFilters: string
  witnessesListForTeaming: Array<IWitnessForTeaming>
  hostSalonsListForTeaming: Array<IHostSalonForTeaming>
  isGetTeamingDataLoading: boolean
  selectedForTeaming: IDetailedWitnessForTeaming | IDetailedHostSalonForTeaming
  relevantOptionsForTeamingList: Array<
    IDetailedHostSalonForTeaming | IDetailedWitnessForTeaming
  >
  teamingTableParmas: TableTeamingParams
  arePaginationOptionsLoading: boolean
  isNextPaginationPageAvailable: boolean
  nextTeamingOptionsPaginationPage: number
  currentSalonDetails: IHostSalon
  error: string
  isIOSModalOpen?: boolean
}

export interface ISalonTeamingProps extends RouteComponentProps {}

export enum EdisplayInfomationType {
  witness = 'witness',
  hostSalon = 'salons',
  none = '',
}
export interface IMapViewProps {
  pageHeight: string
  witnessesListForTeaming: Array<IWitnessForTeaming>
  hostSalonsListForTeaming: Array<IHostSalonForTeaming>
  onSideForTeamingClick: (selectedForTeaming, type) => void
  initMarkerSelected: () => void
  activeTeamingSide: EdisplayInfomationType
}

export interface ISidebarSalonTeamingProps {
  displayInfomationType?: EdisplayInfomationType
  isLoading: boolean
  selectedForTeaming: IDetailedWitnessForTeaming | IDetailedHostSalonForTeaming
  pageHeight: string
  selectedForTeamingSummary: IWitnessForTeaming | IHostSalonForTeaming
  relevantOptionsForTeamingList: Array<
    IDetailedHostSalonForTeaming | IDetailedWitnessForTeaming
  >
  onAssignedSalon: (assignedId: number) => void
  isContacted: boolean
  areNoResultsAvailable: boolean
  cityParams: { city: string[] }
  nextPaginationPage: number
  isNextPaginationPageAvailable: boolean
  arePaginationOptionsLoading: boolean
  onSideForTeamingClick: (selectedForTeamingData: any, type: EdisplayInfomationType) => void;
}

export interface ITeamingOptionsContainerProps {
  optionData: IDetailedHostSalonForTeaming | IDetailedWitnessForTeaming
  displayInfomationType: EdisplayInfomationType
  onAssignedSalon: (assignedId: number) => void
}

export interface ISelectedUserDetailsProps {
  displayInfomationType: EdisplayInfomationType
  selectedForTeamingDetails:
    | IDetailedWitnessForTeaming
    | IDetailedHostSalonForTeaming
  isCardClose: boolean
  selectedForTeamingSummary: IWitnessForTeaming | IHostSalonForTeaming
  sideBarError: ESideBarErrors
}
export interface IWitnessDetailsProps {
  witnessDetails: IDetailedWitnessForTeaming
  distance?: string
  direction: string
  notPreciseLocation: boolean
}

export interface IHostSalonDetailsProps {
  hostSalonsDetails: IDetailedHostSalonForTeaming
  distance?: string
  notPreciseLocation: boolean
  isContacetHost?: boolean
  direction: string
}

export interface IWitnessAssignedDetailsProps {
  name: string
  address: string
  displayInfomationType: EdisplayInfomationType
  distance?: string
}

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import history from './history';
import store, { persistor } from './store';
import Routes from './components/Routes';
import './utils/css/fonts.css';
import { GlobalStyle } from './theme';
import { setAxiosHeader } from './store/middleware/auth.middleware';
import './matrielUiStyles.css';
import { localStorageAuthKey } from './utils/constants/auth';
import ErrorBoundary from './components/common/ErrorBoundary/ErrorBoundary';
import { AccessibilityLanguageProvider } from './components/Admin/SalonTeaming/AccessibilityLanguageContext';

const App: React.FC = () => {
	useEffect(() => {
		const authReducer = JSON.parse(localStorage.getItem(localStorageAuthKey));
		if (authReducer !== null) {
			// if token exist, set header and remove "" from token
			if (JSON.parse(authReducer.token)) setAxiosHeader(authReducer.token.replace(/['"]+/g, ''));
		}
	}, []);

	return (
		<ErrorBoundary>
			<AccessibilityLanguageProvider>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<GlobalStyle />
						<Router history={history}>
							<Routes />
						</Router>
					</PersistGate>
				</Provider>
			</AccessibilityLanguageProvider>
		</ErrorBoundary>
	);
};

export default App;
